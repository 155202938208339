import React, {useEffect, useState} from "react";
import {Magic} from "magic-sdk";

const publishableMagicAPIKey = 'pk_live_01E66D04C07EDD82';
const magicTestMode = false;

const useMagic = ({ apiKey = publishableMagicAPIKey, testMode = magicTestMode } = {}) => {
  const [magic, setMagic] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  useEffect(async () => {
    const magic = new Magic(apiKey, {testMode});
    magic.preload();
    setMagic(magic)
    setLoggedIn(await magic.user.isLoggedIn());
  }, [apiKey, testMode]);
  return { magic, loggedIn };
};

export default useMagic;
