import React, {useState} from "react";
import useApplura from "../hooks/applura.js";
import useName from "../hooks/name.js";

const relAppluraCreateForm = "https://docs.applura.com/operations/link-relations/create-form";

const termsURL = "https://www.applura.com/legal/terms-of-service";
const userAgreementURL = "https://www.applura.com/legal/user-agreement";
const betaDefinitionURL = "https://en.wikipedia.org/wiki/Software_release_life_cycle#Beta"
const supportPageURL = "https://www.applura.com/support"

const Conditions = ({ children, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e.target.elements.namedItem('accept_conditions').value);
  };
  return <form className="form__registration" onSubmit={handleSubmit}>
    <p>We're working hard to build a tool you'll adore, but that means Applura is currently a&nbsp;
      <a target="_blank" href={betaDefinitionURL}>beta software</a> product.</p>
      <p>Please share your feedback and critiques directly by email at beta@applura.com. There may be bugs, but we hope you'll understand.</p>
    <p>By checking this box, you agree to our <a target="_blank" href={termsURL}>terms of service</a> and the&nbsp;
      <a target="_blank" href={userAgreementURL}>beta user agreement</a>.</p>
    <label id="accept_conditions-label" className="label" htmlFor="accept_conditions">
      <input id="accept_conditions" name="accept_conditions" type="checkbox" required autoFocus />
      I understand
    </label>
    <button className="btn btn--primary btn--center">{children}</button>
  </form>;
}

const Username = ({ onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e.target.elements.namedItem('preferred_username').value);
  };
  return <form className="form__registration" onSubmit={handleSubmit}>
    <label id="preferred_username-label" className="label" htmlFor="preferred_username">Time to pick a display name!</label>
    <input id="preferred_username" name="preferred_username" type="text" size="30" required autoFocus />
    <p>Don't worry, you can always change it later.<br/>When you're ready, hit <i>Enter</i></p>
  </form>;
}

const RegistrationForm = ({ data }) => {
  const [ , setUsernameContext ] = useName();
  const [ preferredUsername, setPreferredUsername ] = useState("");
  const { submit } = useApplura();
  const {
    form: {
      submit: text,
      method,
      action,
      rel,
      data: dataToSubmit,
    }
  } = data;
  // Ensure the submission requirements haven't changed.
  if (rel !== relAppluraCreateForm) {
    return <p>We're sorry, something went wrong.</p>
  }
  // The form makes sense, build it.
  const handleSubmit = async (accepted) => {
    const submitAction = { method, action };
    const submitData = { ...dataToSubmit, attributes: { preferredUsername, acceptedConditions: accepted === "on" }};
    if (await submit(submitAction, submitData)) {
      setUsernameContext(preferredUsername);
    }
  }
  return <div id="app" className="full-center">
    <main>
      { !preferredUsername.length ? <Username onSubmit={setPreferredUsername} /> : <Conditions onSubmit={handleSubmit}>{ text }</Conditions> }
    </main>
  </div>
};

export default RegistrationForm;