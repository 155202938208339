import React, {useState} from "react";
import useApplura from "../hooks/applura";

const relAcceptInvitation = 'https://docs.applura.com/operations/link-relations/accept-invitation';

const InvitationForm = ({ data }) => {
  const { submit: doSubmit } = useApplura();
  const [pending, setPending] = useState(false);
  const { submit, invitation } = data;
  console.assert(submit.rel === relAcceptInvitation, 'unrecognized submit.rel');
  const { emailAddress: fromEmail } = invitation?.data?.from?.data;

  const handleClick = async () => {
    setPending(true);
    await doSubmit(submit);
    setPending(false);
  };

  return <main>
    <p>Welcome! One last step…</p>
    <button id="authenticate" className="btn btn--secondary" onClick={handleClick} disabled={pending}>
      { submit.title }
    </button>
    <p><sub>You were invited by {fromEmail}.</sub></p>
  </main>
}

export default InvitationForm;