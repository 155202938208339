import React from "react";
import Link from "../components/link.jsx";

const Project = ({ project }) => {
  const {
    label,
    status,
    statusLabel,
    plan,
    action,
    links,
  } = project;
  const buttonLink = action ? action : links.get('external');
  const planName = plan?.data?.label;
  const selfLink = links.get('self');
  return (
    <li className={`overview-row`}>
      <h3>{ selfLink ? <Link link={selfLink}>{ label }</Link> : label }</h3>
      { buttonLink ? <a className="action btn btn--secondary" href={ buttonLink.href }>{ buttonLink.title }</a> : null }
      <p className={ status !== 'ok' ? `status--${status}` : `` }>Status: { statusLabel }</p>
      { planName ? <p>Plan: { planName }</p> : null }
    </li>
  );
}

const ProjectList = ({ projects }) => (
  <ul className={`overview-list`}>
    { projects.map(project => <Project key={ project.id } project={ project } />)}
  </ul>
);

const ProjectOverview = ({ data }) => {
  const { projects } = data;
  const rows = projects.data;
  const createForm = projects?.links['create-form'];
  return (
    <div id="app" className="overview">
      <main>
        <h1>Projects</h1>
        { createForm ? <a className="btn btn--primary" href={ createForm.href }>{ createForm.title }</a> : null }
        { rows.length ? <ProjectList projects={ rows } /> : null }
      </main>
    </div>
  );
}

export default ProjectOverview;
