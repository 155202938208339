import React from "react";

const Free = () => (
  <div className="product__price">
    <span className="number">Custom</span>
    <div className="unit">Find the perfect fit</div>
  </div>
);

const Price = ({price}) => {
  const {
    currencySymbol,
    number,
    unit,
    trial,
  } = price;

  const nicePrice = (new Intl.NumberFormat('en-US')).format(number/100);

  if (trial) {
    const { count, unit: trialUnit } = trial;
    const plurals = {
      "day": "days",
      "month": "months",
      "year": "years",
    }
    const duration = count > 1 ? plurals[trialUnit] : trialUnit;
    return <div className="product__price">
      <div className="product__price--overridden"><span className="currency-symbol">{currencySymbol}</span><span className="number">{nicePrice}</span></div>
      <span className="currency-symbol">{currencySymbol}</span>0
      <div className="unit">Free for {count} {duration}, then {currencySymbol}{nicePrice}&nbsp;/&nbsp;{unit}</div>
    </div>
  }

  return (
    <div className="product__price">
      <span className="currency-symbol">{currencySymbol}</span><span className="number">{nicePrice}</span><div className="unit">/&nbsp;{unit}</div>
    </div>
  );
};

const Product = ({ product, feature = false }) => {
  const {
    title,
    price,
    description,
    features,
    payment,
  } = product;
  return <article className="product">
    <h2 className="product__title">{title}</h2>
    <p className="product__description">{description}</p>
    { price ? <Price price={price} /> : <Free /> }
    <a className={`product__purchase btn btn--${feature ? 'primary' : 'secondary'}`} href={payment.href}>{payment.title}</a>
    <ul className="product__features">
      {features.map((feature)  => <li key={feature}>{feature}</li>)}
    </ul>
  </article>;
};

export default Product;
