const trimQuotes = s => s.replace(/^"/g, '').replace(/"$/g, '');

const trimWhitespace = s => s.trim();

export function parseLinkHeader(header) {
  return header.split(',').reduce((links, link) => {
    const params = link.split(';').map(trimWhitespace);
    const href = params[0].slice(1, -1);
    const attrs = params.slice(1).reduce((attrs, param) => {
      const [key, value] = param.split('=').map(trimQuotes);
      attrs[key] = key === 'rel' ? value.split(' ') : value;
      return attrs;
    }, {});
    return [...links, {href, ...attrs}];
  }, []);
}
