import React from "react";

const Header = ({ title, children }) => (
  <header className="region region-sticky">
    <div className="layout-container region-sticky__items">
      <div className="region-sticky__items__inner">
        <div
          id="block-gin-page-title"
          className="block block-core block-page-title-block"
        >
          <h1 className="page-title">{title}</h1>
        </div>

        {children}
      </div>
    </div>
  </header>
);

export default Header;