import React from 'react';

const ProblemPage = ({ detail = null, problem = {} }) => {
  console.assert(detail || problem.doc, 'ProblemPage requires either a detail or a problem.doc')
  const details = detail ? [detail] : [];
  if ('doc' in problem) {
    problem.doc.errors.forEach((error) => {
      console.info(`${error.title}:`, error.detail, error);
      details.push(error.detail);
    });
  }
  return (
    <div className="layout-container">
      <div className="layer-wrapper">
        <ol>
          {details.map((detail) => <li>{detail}</li>)}
        </ol>
      </div>
      <p><sub>Additional details have be logged to the web developer console.</sub></p>
    </div>
  )
}

export default ProblemPage;
