import React from "react";
import useApplura from "../hooks/applura.js";
import useName from "../hooks/name.js";

const relAppluraCreateForm = "https://docs.applura.com/operations/link-relations/create-form";

const ProjectLabel = ({ onSubmit }) => {
  const [ name ] = useName();
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e.target.elements.namedItem('project_label').value);
  };
  return <form className="form__registration" onSubmit={handleSubmit}>
    <label id="project_label-label" className="label" htmlFor="project_label">Thanks{name ? ` ${name}` : ""}! Now choose a project label:</label>
    <input id="project_label" name="project_label" type="text" size="30" required autoFocus />
    <p>Just like your display name, you can always change the project label later.<br/>When you're happy with it, hit <i>Enter</i></p>
  </form>;
}

const ProjectForm = ({ data }) => {
  const { submit } = useApplura();
  const {
    form: {
      method,
      action,
      rel,
      data: dataToSubmit,
    }
  } = data;
  // Ensure the submission requirements haven't changed.
  if (rel !== relAppluraCreateForm) {
    return <p>We're sorry, something went wrong.</p>
  }
  // The form makes sense, build it.
  const handleSubmit = async (label) => {
    const submitAction = { method, action };
    const submitData = { ...dataToSubmit, attributes: { label }};
    await submit(submitAction, submitData);
  }
  return <div id="app" className="full-center">
    <main>
      <ProjectLabel onSubmit={handleSubmit} />
    </main>
  </div>
};

export default ProjectForm;