import React from 'react';

import Products from "../components/products";

const PricingPage = ({ data }) => {
  const { subtitle, plans } = data;
  const products = plans?.data || [];
  return <>
    <h1 className="subtitle">{subtitle}</h1>
    <Products products={products} />
    <div className="pricing__contact">
      <p><em>Party plan seats are counted per project.<br/>Solo plan limits may be revised at any time.</em></p>
    </div>
  </>
}

export default PricingPage;
