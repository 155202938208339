import React from "react";

const ProjectDetail = ({ data }) => {
  const {
    label,
    status,
    statusLabel,
    plan,
    action,
    links,
  } = data;
  const buttonLink = action ? action : links.get('external');
  const planName = plan?.data?.label;
  return <div id="app" className="full-center project-detail">
    <main>
      <h1>{ label }</h1>
      <p className={ status !== 'ok' ? `status--${status}` : `` }>Status: { statusLabel }</p>
      { planName && <p>Plan: { planName }</p> }
      { buttonLink && <a className="action btn btn--primary btn--wide" href={ buttonLink.href }>{ buttonLink.title }</a> }
    </main>
  </div>
}

export default ProjectDetail;
