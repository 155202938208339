import React, {useState} from "react";
import useApplura from "../hooks/applura.js";
import useName from "../hooks/name.js";
import useMagic from "../hooks/magic.js";
import LoginOTPPage from "./login-otp.jsx";

const relAppluraEditForm = "https://docs.applura.com/operations/link-relations/edit-form";
const relSubscriptionPortal = "https://docs.applura.com/operations/link-relations/subscription-portal";

const AccountForm = ({ data }) => {
  const { magic, loggedIn: loggedInWithMagic } = useMagic();
  const magicClientReady = magic !== null;
  const [ , setUsernameContext ] = useName();
  const { submit } = useApplura();
  const {
    form: {
      submit: text,
      method,
      action,
      rel,
      data: {
        attributes: {
          email,
          preferredUsername,
        },
        ...dataToSubmit
      },
    },
    links,
  } = data;
  const [ btnText, setBtnText ] = useState(text);
  const [ formBlocked, setFormBlocked ] = useState(false);
  const [ formErrored, setFormErrored ] = useState(false);
  const [ mail, setMail ] = useState(email);
  const [ name, setName ] = useState(preferredUsername);
  const noSubmit = !magicClientReady || formBlocked || formErrored;
  // Ensure the submission requirements haven't changed.
  if (rel !== relAppluraEditForm) {
    return <p>We're sorry, something went wrong.</p>
  }
  // The form makes sense, build it.
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormBlocked(true);
    setBtnText('Updating…')
    if (!magicClientReady) {
      return
    }
    if (mail !== email) {
      let updated = false;
      try {
        updated = await magic.user.updateEmail({email: mail, showUI: true});
      } catch {
        setBtnText("Please try again")
        setFormBlocked(false)
        return
      }
      if (!updated) {
        setFormErrored(true);
        setBtnText("An error occurred")
        return
      }
    }
    const submitAction = { method, action };
    const submitData = { ...dataToSubmit, attributes: { email: mail, preferredUsername: name }};
    if (await submit(submitAction, submitData)) {
      setUsernameContext(name);
    }
    setBtnText("Saved!")
    setFormBlocked(false);
    setTimeout(() => {
      setBtnText(text);
    }, 5000);
  }

  const SubscriptionPortalLink = () => {
    if (links && links.has(relSubscriptionPortal)) {
      const portalLink = links.get(relSubscriptionPortal);
      return <a href={portalLink.href} className="btn btn--secondary btn--wide" rel={portalLink.rel} target="_blank">{portalLink.title}</a>
    } else {
      return null;
    }
  };

  return <div id="app" className="full-center project-detail">
    <main>
      <h2>My account</h2>
      <form onSubmit={handleSubmit} className="form__narrow">
        <label id="email-label" className="label" htmlFor="email">Contact email address</label>
        <input id="email" name="email" type="email" size="30" required autoFocus value={mail}
               onChange={(e) => setMail(e.target.value)}/>
        <label id="preferred_username-label" className="label" htmlFor="preferred_username">Preferred display
          name</label>
        <input id="preferred_username" name="preferred_username" type="text" size="30" required autoFocus value={name}
               onChange={(e) => setName(e.target.value)}/>
        <button className="btn btn--primary btn--wide" disabled={noSubmit}>{btnText}</button>
        <SubscriptionPortalLink/>
        <a className="btn btn--secondary btn--wide" href="/logout">Log out</a>
      </form>
    </main>
  </div>
};

export default AccountForm;
