import React, {useEffect, useState} from 'react';
import useApplura from "../hooks/applura";

const CheckoutSuccess = ({ data }) => {
  const { refresh } = useApplura();
  const { subtitle, message, next } = data;
  const [ pending, setPending ] = useState(false);
  useEffect(() => {
    if (!next && !pending) {
      setPending(true);
      setTimeout(() => {
        refresh();
        setPending(false);
      }, 3000);
    }
  }, [next, pending]);
  return <div id="app" className="full-center">
    <main>
      <h1 className="subtitle">{subtitle}</h1>
      {message && <p>{message}</p>}
      {next && <a className="btn btn--primary" href={next.href}>{next.title}</a>}
    </main>
  </div>
}

export default CheckoutSuccess;
