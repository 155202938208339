import React, { useState } from "react";
import FormInputText from "../components/formInputText";

const LoginPage = ({ data: _ }) => {
  const [value, setValue] = useState("");
  const onChange = (event) => {
    setValue(event.target.value);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    console.log(`submitting ${value}`);
  };

  return (
    <div className="layout-container">
      <div className="layer-wrapper">
        <form onSubmit={onSubmit} className="login form-login">
          <FormInputText
            type="email"
            label="email"
            isRequired={true}
            onChange={onChange}
          />
          <button
            type="submit"
            className="button button--primary"
            disabled={value === ""}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
