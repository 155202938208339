import React, {useContext} from "react";
import { useEffect, useState } from "react";
import { Magic } from "magic-sdk";
import useApplura from "../hooks/applura";
import useMagic from "../hooks/magic.js";

const publishableMagicAPIKey = 'pk_live_01E66D04C07EDD82';
const magicTestMode = false;

const LoginForm = ({ magicSDK, loggedInWithMagic }) => {
  const { follow } = useApplura();
  const [ authenticating, setAuthenticating ] = useState(false);
  const [ helpText, setHelpText ] = useState(<>Type your email, then hit <i>Enter</i> to submit</>);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (authenticating) return;
    setAuthenticating(true);
    let didToken, loggedIn;
    try {
      if (loggedInWithMagic) {
        didToken = await magicSDK.user.getIdToken();
      } else {
        const email = e.target.elements.namedItem('email').value;
        didToken = await magicSDK.auth.loginWithEmailOTP({ email, showUI: true });
      }
      await follow(
        `${window.location.pathname}${window.location.search}${window.location.hash}`,
        {
          headers: {
            authorization: `Bearer ${didToken}`,
          },
        }
      );
    } catch (e) {
      console.error(e);
      setHelpText(<>Something went wrong. Please try again.</>);
    }
    setAuthenticating(false);
  };

  const resetHelpText = () => {
    setHelpText(<>Type your email, then hit <i>Enter</i> to submit</>);
  };

  if (!magicSDK || loggedInWithMagic === null) {
    return <i>Synchronizing…</i>;
  }

  return <>
    <form className="form__authentication" onSubmit={handleSubmit}>
      { authenticating && <p id="authentication-indicator"><i>Authenticating…</i></p> }
      <label id="email-label" className={ `label ${authenticating && 'visually-hidden'}`} htmlFor="email">{ helpText }</label>
      <input id="email" name="email" type="email" size="30" required onChange={resetHelpText} autoFocus />
    </form>
  </>
}

const LoginOTPPage = () => {
  const [showForm, setShowForm] = useState(false);
  const { magic, loggedIn: loggedInWithMagic } = useMagic({
    apiKey: publishableMagicAPIKey,
    testMode: magicTestMode,
  });

  const handleClick = () => setShowForm(true);

  const Greeting = () => <>
    <p>You're almost there!</p>
    <button id="authenticate" className="btn btn--secondary" onClick={handleClick} disabled={loggedInWithMagic === null}>
      { loggedInWithMagic === null ? 'Synchronizing…' : 'Register or log in'}
    </button>
  </>

  return <div id="app" className="full-center">
    <main>{showForm ? <LoginForm magicSDK={magic} loggedInWithMagic={loggedInWithMagic} /> : <Greeting />}</main>
  </div>
}

export default LoginOTPPage;
