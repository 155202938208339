import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { bootstrap } from "https://cdn.applura.com/dist/js/client/v2.js";

import App from "./App";
import {AppluraProvider} from "./contexts/applura";

const listen = async (client, listener) => {
  for await (const { resource, problem } of client.start()) {
    listener({resource, problem});
  }
}

const Glue = ({ client, document }) => {
  const [{ resource, problem }, setData] = useState({ resource: null, problem: null });

  const onUpdate = ({ resource, problem }) => {
    document.title = resource?.title || document.title;
    setData({ resource, problem });
  };

  useEffect(() => {
    listen(client, onUpdate);
    return () => client.stop();
  }, [client]);

  if (!(resource || problem)) {
    return null;
  }

  return <AppluraProvider client={client}>
    <App data={resource} problem={problem} />
  </AppluraProvider>
}

document.addEventListener('DOMContentLoaded', () => {
  const client = bootstrap();
  ReactDOM.hydrate(
    <React.StrictMode>
      <Glue client={client} document={document}/>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
