import React from "react";

const FormInputText = ({ id, label, value, type, onChange, isRequired = false, placeholder, style}) => {
  
  return (
    <div className={`form-item form-item--${type} ${style ? `form-item--${style}` : ''}`}>
      <label
        htmlFor={id}
        className={`form-item__label${isRequired ? " form-required" : ""}`}
      >{ label }</label>
      <input
        id={id}
        type={type}
        className={`form-element form-${type} ${isRequired ? "required" : ""}`}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default FormInputText;