import React from "react";
import Link from "./link.jsx";

const LeftArrowIcon = (props) => (
  <svg {...props} className="icon" width="18mm" height="18mm" viewBox="0 0 18 18" version="1.1" id="svg1" xmlns="http://www.w3.org/2000/svg">
    <defs id="defs1"/>
    <g id="layer1">
      <path id="path3" d="M 11.037458,-3.668023 4.015031,0.38630052 -3.007396,4.4406241 4.015031,8.4949476 11.037458,12.549271 h 5.450425 L 9.4654561,8.4949476 3.7964244,5.2218775 H 35.070664 V 3.6593706 H 3.7964244 L 9.4654561,0.38630052 16.487883,-3.668023 Z" transform="matrix(0.42077453,0,0,1.1099222,3.2430927,4.0712202)"/>
    </g>
  </svg>
);

const AccountIcon = (props) => (
  <svg {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.824 0C16.3468 0 20.824 4.47715 20.824 10C20.824 15.5228 16.3468 20 10.824 20C5.30112 20 0.823975 15.5228 0.823975 10C0.823975 4.47715 5.30112 0 10.824 0ZM10.9837 14C8.94827 14 7.11579 14.8687 5.83673 16.2556C7.20436 17.3474 8.93797 18 10.824 18C12.7935 18 14.5967 17.2883 15.9906 16.1081C14.7196 14.8074 12.9459 14 10.9837 14ZM10.824 2C6.40569 2 2.82397 5.58172 2.82397 10C2.82397 11.8106 3.42547 13.4807 4.43954 14.8214C6.08036 13.0841 8.40542 12 10.9837 12C13.4681 12 15.7173 13.0066 17.3458 14.6342C18.2766 13.3267 18.824 11.7273 18.824 10C18.824 5.58172 15.2423 2 10.824 2ZM10.824 3C13.0331 3 14.824 4.79086 14.824 7C14.824 9.2091 13.0331 11 10.824 11C8.61483 11 6.82397 9.2091 6.82397 7C6.82397 4.79086 8.61483 3 10.824 3ZM10.824 5C9.71937 5 8.82397 5.89543 8.82397 7C8.82397 8.1046 9.71937 9 10.824 9C11.9286 9 12.824 8.1046 12.824 7C12.824 5.89543 11.9286 5 10.824 5Z"
    fill="white"/>
  </svg>
);

const Nav = ({data, ...rest}) => {
  const prev = data?.links.get('prev') || data?.links.get('up') || data?.links.get('start');
  const account = data?.links.get('account');
  const prevLinkTitle = prev?.title || 'Back';
  const accountLinkTitle = account?.title || 'Account';
  return (
    <nav {...rest}>
      <div className="nav-set">
        {prev ? <Link className={'btn btn--outline'} link={prev}><LeftArrowIcon/>{ prevLinkTitle }</Link> : null}
      </div>
      <div className="nav-set">
        {account ?
          <Link className={'btn btn--outline'} link={account} aria-label={ accountLinkTitle } >
            <AccountIcon aria-hidden="true" />
            <span className="link-text">{ accountLinkTitle }</span>
          </Link> : null
        }
      </div>
    </nav>
  );
};

export default Nav;