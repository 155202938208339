import {AppluraContext} from "../contexts/applura";
import {useContext} from "react";
import {parseLinkHeader} from "../lib/http";

async function doSubmit(client, submit, data = null) {
  const init = {
    method: submit.method,
    headers: {
      'accept': 'application/vnd.api+json',
    }
  };
  if (data || submit.data) {
    const doc = { data: data || submit.data };
    init.body = JSON.stringify(doc);
    init.headers['content-type'] = 'application/vnd.api+json';
  }
  const success = await client.follow(submit.action, init);
  if (success) {
    const response = client.response();
    if (response.ok && response.headers.has('link')) {
      const links = parseLinkHeader(response.headers.get('link'));
      const nextLink = links.find(link => !!link.rel.find(rel => rel === 'next'));
      if (nextLink) {
        window.location = nextLink.href;
      }
    }
  }
  return success;
}

/**
 * useApplura provides easy access to the Applura JS client from the Applura context.
 *
 * As an experiment, it also decorates it with a submit function as if it were provided by the client itself.
 */
const useApplura = () => {
  const client = useContext(AppluraContext);
  return { ...client, refresh: () => client.refresh(), submit: (submit, data = null) => doSubmit(client, submit, data) };
}

export default useApplura;
