import React, { useState }  from "react";
import FormInputText from "../components/formInputText";

const ProfilePage = ({ data }) => {
  const { email } = data;
  const currentMessage = email
    ? `Current email address: ${email}`
    : "Set your email address";

  const [value, setValue] = useState("");
  const onChange = (event) => {
    setValue(event.target.value);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    console.log(`submitting ${value}`);
  };

  return (
    <div className="layout-container">
      <div className="layer-wrapper">
        <form onSubmit={onSubmit} className="login form-edit-profile">
          <p>{currentMessage}</p>
          <FormInputText
            type="email"
            label="Change email"
            isRequired={true}
            onChange={onChange}
          />
          <button
            type="submit"
            className="button button--primary"
            disabled={value === ""}
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
