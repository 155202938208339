import React, {useState} from "react";
import Header from "./components/header";
import InvitationForm from "./pages/invitation-form";
import LoginOTPPage from "./pages/login-otp";
import LoginPage from "./pages/login";
import PricingPage from "./pages/pricing";
import ProblemPage from "./pages/problem";
import ProfilePage from "./pages/profile";
import CheckoutSuccess from "./pages/checkout-success";
import RegistrationForm from "./pages/registration-form.jsx";
import ProjectForm from "./pages/project-form.jsx";
import {UsernameContextProvider} from "./contexts/name.js";
import AccountForm from "./pages/account-form.jsx";
import ProjectOverview from "./pages/project-overview.jsx";
import ProjectDetail from "./pages/project-detail.jsx";
import Nav from "./components/nav.jsx";

const pages = {
  "account-form": AccountForm,
  "invitations:accept-form": InvitationForm,
  "login-page": LoginPage,
  "plans:checkout-success": CheckoutSuccess,
  "plans:choose": PricingPage,
  "pricing-page": PricingPage,
  "profile-page": ProfilePage,
  "project:detail": ProjectDetail,
  "project-form": ProjectForm,
  "projects:overview": ProjectOverview,
  "registration-form": RegistrationForm,
};

const App = ({ data, problem = null }) => {
  const [ name, setName ] = useState();
  const { type } = data || {};
  const Page = pages[type];

  if (problem) {
    if (problem.name === 'RequestError' && problem.response.status === 401) {
      return (
        <div className="page-wrapper">
          <LoginOTPPage />
        </div>
      );
    }
    return (
      <div className="page-wrapper">
        <Header title="Problem(s) encountered" />
        <ProblemPage problem={problem} />
      </div>
    );
  } else if (!Page) {
    return (
      <div className="page-wrapper">
        <Header title="Application error" />
        <ProblemPage detail={<span>Unrecognized resource type: <code>{type || 'undefined'}</code></span>} />
      </div>
    );
  }

  return <UsernameContextProvider name={name} setName={setName} >
    { data ? (
      <div className="page-wrapper">
        <Nav data={data} />
        <Page data={data} />
      </div>
    ) : (
      <div className="page-wrapper">Loading&hellip;</div>
    )}
  </UsernameContextProvider>;
};

export default App;
