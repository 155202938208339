import React from "react";
import useApplura from "../hooks/applura.js";

const Link = ({ link, attributes = null, active = null, children = null, className, ...props }) => {
  const { follow } = useApplura();
  const handleClick = (e) => {
    e.preventDefault();
    follow(link);
  }

  return (
    <a {...props} href={link.href} title={link.title} onClick={handleClick} className={`${className} ${active ? 'is-active' : ''}`} {...attributes}>
      {children ? children : link.title}
    </a>
  )
}

export default Link;
